export default {
  Home: {
    title:
      "Projetsolaire.com {'|'} Installer Panneaux Solaires avec Artisan RGE",
    description:
      "Avec projetsolaire.com, découvrez 3+ devis gratuits à -40% des prix du marché, de milliers d'artisans photovoltaïques et batteries en autoconsommation.",
    //title: "Projetsolaire.com {'|'} Plateforme communautaire d'énergie solaire",
    //description: "Avec projetsolaire.com, découvrez 3+ devis gratuits photovoltaïque à -40% des prix du marché, d'artisans de panneaux solaires et batteries en autoconsommation.",
  },
  LeadFlow: {
    title:
      "Potentiel Solaire - Étude horaire sur 25 ans {'|'} projetsolaire.com",
    description:
      "Découvrez votre autoconsommation photovoltaïque avec analyse de l'historique horaire du Linky. Gratuit et sans engagements. Zéro démarchage.",
  },
  ResaleAgreements: {
    title: "Comparez les alternatives à EDF OA",
    description:
      "Consultez les alternatives à EDF OA. Choisissez entre un contrat ou une batterie virtuelle et laissez projetsolaire vous accompagner dans votre démarche.",
  },
  About: {
    title:
      "Engagements de l'Acteur Français du Solaire {'|'} projetsolaire.com",
    description:
      "Découvrez la mission et l'équipe du N°1 bureau d'études photovoltaïque en France, et sa vision de l'informatique au service de la filière française du solaire.",
  },
  HelpHome: {
    title:
      "Manuel d'utilisation et astuces - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpHouseholder: {
    title:
      "Assistance pour Propriétaires - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderYourAccountGuideParentAll: {
    title: "Votre portail internet - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderPaymentsGuideParentAll: {
    title:
      "Paiements, tarification et remboursements - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderPresentationGuideParentAll: {
    title:
      "Choisir son système solaire - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderQuotingGuideParentAll: {
    title:
      "Choisir son devis sur-mesure - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderTechnicalVisitGuideParentAll: {
    title: "Valider la faisabilité - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderContractGuideParentAll: {
    title: "Investir dans le solaire - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderAdministrationGuideParentAll: {
    title:
      "Réalisation des démarches administratives - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderInstallationGuideParentAll: {
    title: "Pose & mise en service - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideHouseholderLegalGuideParentAll: {
    title: "Mentions juridiques - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpInstaller: {
    title: "Service client pour Pros - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerYourAccountGuideParentAll: {
    title: "Votre compte en ligne - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerPresentationGuideParentAll: {
    title:
      "Études photovoltaïques en ligne - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerQuotingGuideParentAll: {
    title: "Mise en relation - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerAdministrationGuideParentAll: {
    title:
      "Gestion administrative automatisée - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerHardwareGuideParentAll: {
    title: "Planification du matériel - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerPaymentsGuideParentAll: {
    title:
      "Paiements, tarification et remboursements - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerProjectManagementGuideParentAll: {
    title: "Vos projets solaires - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerPostInstallationGuideParentAll: {
    title: "Service après-installation - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  HelpGuideInstallerLegalGuideParentAll: {
    title: "Mentions juridiques - Centre d'aide {'|'} projetsolaire.com",
    description:
      "Besoin d'aide ou vous souhaitez nous contacter ? Retrouvez l'assistance, documentation, articles et vidéos des fonctionnalités web et mobile et des services.",
  },
  Blog: {
    title: "Astuces, Guides, Centre de renseignements {'|'} projetsolaire.com",
    description:
      "Retrouvez tous nos conseils pour financer, optimiser et entretenir vos panneaux solaires. Autoconsommation, économies d'énergies, aides et démarches.",
  },
  CareerHome: {
    title:
      "Avoir un impact sur l'énergie solaire | Carrières chez projetsolaire",
    description:
      "Rejoignez-nous pour faire quelque chose de grand. Nous contribuons à l'avenir de l'énergie propre et décentralisée en France, parmi les plus beaux paysages du monde.",
  },
  CareerJobPosts: {
    title: "Opportunités et postes à pourvoir | Carrières chez projetsolaire",
    description:
      "Rejoignez-nous pour faire quelque chose de grand. Nous contribuons à l'avenir de l'énergie propre et décentralisée en France, parmi les plus beaux paysages du monde.",
  },
  AmbassadorsHome: {
    title: "Parlez du solaire et gagnez de l'argent {'|'} projetsolaire.com",
    description:
      "Gagnez des milliers d'euros grâce à projetsolaire avec votre bouche-à-oreille, en obtenant factures élec de vos proches et voisins, puis en parlant d'artisans.",
  },
  InstallerHome: {
    title:
      "Projetsolaire.com {'|'} N°1 de la Gestion tout-en-un photovoltaïque",
    description:
      "Plateforme dimensionnement et administratif pour installateurs de panneaux solaires, artisan RGE, poseur PV, développeur installation photovoltaïque en BT et HTA.",
  },
  InstallerAdministration: {
    title: "N°1 des Démarches Administratives du PV {'|'} projetsolaire.com",
    description:
      "Expert des démarches administratives photovoltaïque. Déléguez et suivez vos dossiers urbanisme, Enedis ou régie ELD, Consuel, EDF OA ou batterie virtuelle.",
  },
  InstallerDigitalSalesTools: {
    title: "Logiciel de Dimensionnement PV et Vente {'|'} projetsolaire.com",
    description:
      "Études photovoltaïques avec données horaires de consommation du compteur Linky, productible avec masque d'ombres pour audit RGE, en marque blanche.",
  },
  InstallerFindNewClients: {
    title: "Mise en relation leads PV sans commissions {'|'} projetsolaire.com",
    description:
      "Plateforme en ligne d'appel d'offres pour chantiers photovoltaïques. Le poseur choisit le prix et le matériel. La visite technique est obligatoire.",
  },
  InstallerCaseStudiesHomeOverview: {
    title:
      "Études de cas et Témoignages toutes tailles {'|'} projetsolaire.com",
    description:
      "Avis sur projetsolaire de professionnels, installateurs, poseurs et développeurs du photovoltaïque, cas d'usage sur chantiers HTA, BT>36, particulier.",
  },
  InstallerCaseStudiesHomeAll: {
    title:
      "Tous les témoignages de pros de toutes tailles {'|'} projetsolaire.com",
    description:
      "Avis sur projetsolaire de professionnels, installateurs, poseurs et développeurs du photovoltaïque, cas d'usage sur chantiers HTA, BT>36, particulier.",
  },
  Pricing: {
    title: "Tarifs, prix et coût pour Professionnels {'|'} projetsolaire.com",
    description:
      "Grille tarifaire de projetsolaire et des services numériques, démarches administratives, études, mise en relation, plateforme de gestion de chantiers.",
  },
  ToolsHome: {
    title:
      "Outils gratuits pour installation photovoltaïque {'|'} projetsolaire.com",
    description:
      "Expert du photovoltaïque et N°1 bureau d'études en France, développe outils en ligne et simulateurs, gratuit et libre d'accès pour installation de panneaux solaires.",
  },
  ToolEnedisConstructionCost: {
    title:
      "Simulateur Coûts Travaux Raccordement Enedis - Outil gratuit {'|'} projetsolaire.com",
    description:
      "Calcul du coût du renforcement du réseau électrique BT et HTA par Enedis. Alternative au devis PTF de la demande de raccordement photovoltaïque.",
  },
  ToolCarbonModelCalculator: {
    title:
      "Calcul Impact CO2e du Solaire - Outil gratuit {'|'} projetsolaire.com",
    description:
      "Expert du photovoltaïque et N°1 bureau d'études en France, développe outils en ligne et simulateurs, gratuit et libre d'accès pour installation de panneaux solaires.",
  },
  ToolQuoteChecker: {
    title:
      "Vérifier Devis Photovoltaïque - Outil gratuit {'|'} projetsolaire.com",
    description:
      "Expert du photovoltaïque et N°1 bureau d'études en France, développe outils en ligne et simulateurs, gratuit et libre d'accès pour installation de panneaux solaires.",
  },
  ToolHistoricalMonumentChecker: {
    title:
      "Vérifier Urbanisme et Zone ABF - Outil gratuit {'|'} projetsolaire.com",
    description:
      "Expert du photovoltaïque et N°1 bureau d'études en France, développe outils en ligne et simulateurs, gratuit et libre d'accès pour installation de panneaux solaires.",
  },
  Support: {
    title: "Service Client et Contact {'|'} projetsolaire.com",
    description:
      "Discutez avec nos équipes aujourd'hui. Service client 360° disponible via chat, email, téléphone, SMS, et visio. Disponible tous les jours ouvrés sur l'année.",
  },
  SignUp: {
    title: "Inscription {'|'} projetsolaire.com",
    description:
      "Le N°1 bureau d'études photovoltaïque en France développe la première plateforme d'appel d'offres pour installation panneaux solaires, démarches administratives.",
  },
  SignUpCallback: {
    title: "Confirmation inscription {'|'} projetsolaire.com",
    description:
      "Le N°1 bureau d'études photovoltaïque en France développe la première plateforme d'appel d'offres pour installation panneaux solaires, démarches administratives.",
  },
  EnableAccountCallback: {
    title: "Activation de votre compte {'|'} projetsolaire.com",
    description:
      "Le N°1 bureau d'études photovoltaïque en France développe la première plateforme d'appel d'offres pour installation panneaux solaires, démarches administratives.",
  },
  SignIn: {
    title: "Connexion {'|'} projetsolaire.com",
    description:
      "Le N°1 bureau d'études photovoltaïque en France développe la première plateforme d'appel d'offres pour installation panneaux solaires, démarches administratives.",
  },
  AdminSignIn: {
    title: "Accès privé",
    description:
      "Ne partagez jamais votre mot de passe. Votre mot de passe n'est jamais demandé ailleurs que lors de votre connexion.",
  },
  SupplierSignIn: {
    title: "Connexion - Portail Fournisseurs {'|'} projetsolaire.com",
    description: "Connexion - Fournisseurs description",
  },
  AmbassadorSignIn: {
    title: "Connexion - Portail Ambassadeurs {'|'} projetsolaire.com",
    description: "Connexion - Ambassadeurs description",
  },
  ResetPassword: {
    title: "Réinitialisation du mot de passe {'|'} projetsolaire.com",
    description: "Réinitialiser le mot de passe description",
  },
  ResetPasswordSupplier: {
    title:
      "Réinitialisation du mot de passe - Fournisseurs {'|'} projetsolaire.com",
    description: "Réinitialiser le mot de passe - Fournisseurs description",
  },
  ResetPasswordCallback: {
    title: "Réinitialisation du mot de passe - Callback",
    description: "La plateforme est sécurisé",
  },
  InviteCallbackInstaller: {
    title: "Invitation au compte de l'entreprise {'|'} projetsolaire.com",
    description:
      "Compte personnel pour la gestion de chantiers photovoltaïques. Mise à disposition gratuitement par le bureau d'études numérique français projetsolaire.com",
  },
  InviteCallbackSupplier: {
    title: "Invitation au portail e-commerce {'|'} projetsolaire.com",
    description:
      "Compte de E-commerce en ligne pour vente de matériel photovoltaïque. Mise à disposition gratuitement par le bureau d'études numérique français projetsolaire.com",
  },
  InviteCallbackUser: {
    title:
      "Invitation à l'espace client de son projet solaire {'|'} projetsolaire.com",
    description:
      "Portail client de suivi en ligne de son projet solaire. Mise à disposition gratuitement par le bureau d'études numérique français projetsolaire.com",
  },
  ReportsHome: {
    title: "Rapport du Solaire en France {'|'} projetsolaire.com",
    description:
      "Le N°1 bureau d'études photovoltaïque en France publie son rapport annuel. Découvrez les tendances, renseignements et données de la filière photovoltaïque.",
  },
}
